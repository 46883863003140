<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <span class="item">
                            <a-range-picker @change="registerChange" v-model="form.datePicker" :disabled-date="disabledDate" :placeholder="['当期订单起始日期', '当期订单结束日期']" style="width: 300px;"/>
                        </span>
                        <span class="item">
                            <a-button class="margin_right10" type="primary" @click="searchClick">添加查询任务</a-button>
                        </span>
                    </div>
                </div>
                <div class="contant-box">
                    <p class="title">导出报表表头展示</p>
                    <div class="tabShow">
                        <table border="1">
                            <tr>
                                <th>时间周期</th>
                                <th colspan="9">抖音-申怡旗舰店/视频号-领读人申怡/APP</th>
                            </tr>
                            <tr>
                                <th>课程/商品名称</th>
                                <th>当期订单数</th>
                                <th>当期GMV</th>
                                <th>当期用户数</th>
                                <th>其中首购用户数</th>
                                <th>其中首购用户消费GMV</th>
                                <th>首购用户中7日复购用户数</th>
                                <th>首购用户中7日复购GMV</th>
                                <th>首购用户中30日复购用户</th>
                                <th>首购用户中30日复购GMV</th>
                            </tr>
                        </table>
                    </div>
                    
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.taskId" :loading="tableLoading" :pagination="false">
                        <span slot="Status" slot-scope="text, record">
                            <span>{{ record.Status == 1 ? '未开始' : record.Status == 2 ? '进行中' : record.Status == 3 ? '已完成' : '--' }}</span>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a @click="isDownloadClick(record)" v-if="record.Status == 3">下载</a>
                            <a v-else>--</a>
                        </span>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {AddStatistics, GetStatisticsList} from "@/request/api/reportFormManage";
import {domainName} from "@/config";
import axios from "axios";
export default {
    components: {MyPagination},
    created() {
        this.getTagList();
    },
    
    data() {
        return  {
            form:{
                courseName:undefined,
                registerDate:'',
                registerEndDate:'',
                status:0,
                datePicker:[],
            },
            spinning: false, // 全局loading
            tableLoading: false,
            columns: [
                {
                    title: "报表名称",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "系统生成时间",
                    dataIndex: "create_time",
                    key: "create_time",
                },
                {
                    title: "进度",
                    dataIndex: "Status",
                    key: "Status",
                    scopedSlots: {customRender: "Status"},
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "130px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0,// 列表的总条数
            options:[],
            isDateShow:true,
            
            // 展示表头
            columnsShow:[
                {
                    title: "报表名称",
                    dataIndex: "userName",
                    key: "userName",
                },
                {
                    title: "系统生成时间",
                    dataIndex: "nickName",
                    key: "nickName",
                    scopedSlots: {customRender: "nickName"},
                },
                {
                    title: "进度",
                    dataIndex: "vipStatus",
                    key: "vipStatus",
                    scopedSlots: {customRender: "vipStatus"},
                },
            ],
            tableListShow:[],
        };
    },
    
    watch: {
        $route(to) {
            if (to.path === "/reportFormManage/transactionIndex") {
                this.getTagList();
            }
        },
    },
    
    methods: {
      // 限制日期
      disabledDate(current) {
        return current && current > Date.now();
      },
        // 搜索
        searchClick() {
          if(!this.form.registerDate){
            this.$message.error('请选择时间范围！')
            return false
          }
          
          this.tableLoading = true;
          AddStatistics({
              begin_time: this.form.registerDate,
              end_time: this.form.registerEndDate,
            }).then((res) => {
              if (res.code === 200) {
                this.pageNo = 1
                this.getTagList()
              }
              this.tableLoading = false;
            });
        },
        
        // 点击重置
        resetClick(){
            this.pageNo = 1;
            this.getTagList()
        },
        
        // 搜索 - 注册时间
        registerChange(date, dateString){
            if (dateString[0] != "") {
                this.form.registerDate = dateString[0];
                this.form.registerEndDate = dateString[1];
            } else {
                this.form.registerDate = "";
                this.form.registerEndDate = "";
            }
        },
        
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },
        
        // 列表数据
        getTagList() {
            this.tableLoading = true
            GetStatisticsList({
                status: this.form.status,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.data;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
        
        // 下载
        isDownloadClick(record){
          let token = localStorage.getItem("token");
          this.tableLoading = true
          let urlLink = domainName
          if(urlLink != "https://test.shenyiedu.com/"){
            urlLink = 'https://admin.shenyiedu.com/'
          }
          let url = `${urlLink}/adminv2/statistics/DownStatistics`
          let obj = {
            task_id: record.taskId
          }
          axios.post(url,obj,{
            responseType: 'blob',
            headers:{
              'accesstoken':token,
              'authorization':token,
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            let blob = res.data;
            const link = document.createElement('a');
            blob = new Blob([blob], {type: 'application/x-excel'});
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob);
            link.download = `${record.name}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.tableLoading = false
          });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
table{
    width: 100%;
    text-align: center;
    th{
        padding: 10px;
    }
}

.contentRender{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    li {
        text-align: center;
        list-style: none;
    }
    .tables{
        width: 50%;
        .items{
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;
            background: rgba(80, 207, 248, 0.2);
            >.title{
                width: 30%;
                font-size: 13px;
                text-align: right;
                font-weight: 500;
            }
            .substance{
                width: 70%;
                font-size: 13px;
                text-align: left;
                font-weight: 500;
            }
        }
    }
    .chart{
        width: 50%;
        .chartCom{
            width: 100%;
            height: 320px;
            margin-top: -10px;
        }
        .chartTitle{
            font-size: 15px;
            font-weight: 500;
        }
    }
}

.item{
    margin-right: 20px;
    margin-bottom: 10px;
}
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    margin-bottom: 5px;
}

.avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
    flex-wrap: wrap;
}
/deep/.vue-treeselect__control{
    width: 210px;
}
/deep/ .ant-input{
    min-height: 36px !important;
}
/deep/ .ant-select-selection--single{
    min-height: 36px !important;
}
/deep/.ant-select-selection__rendered{
    line-height: 35px;
}
</style>
